import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { routes } from 'config/routes';
import Page from 'pages/Page';

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, i) => (
          <Page key={i} {...route} />
        ))}
        <Route path="*">
          <Redirect to={{ pathname: "/" }} />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
