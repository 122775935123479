import React from 'react';

import { Route, Redirect } from "react-router-dom";

import { mapState } from 'store/state';

export default class Page extends React.Component {
  constructor() {
    super();

    this.state = {
      ...mapState(this, ['isLoggedIn']),
    };
  }

  componentDidMount(){
    document.title = this.props.title;
  }

  render() {
    const { freeAccess, routes, ...route } = this.props;

    return (
      <Route
        path={route.path}
        render={props => (
          freeAccess || this.state.isLoggedIn ? (
           <route.component {...props} routes={routes} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        )}
      />
    );
  }
}
