import firebase from "services/firebaseService";
import * as firebaseui from 'firebaseui';

import { updateState } from 'store/state';

// Initialize the FirebaseUI Widget using Firebase.

const uiConfig = {
  // Will use popup for IDP Providers   sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],
  tosUrl: '/terms-of-service',
  privacyPolicyUrl: '/privacy-policy'
}

let ui

export function authUI(container, successCallback) {
  if (!ui) {
    ui = new firebaseui.auth.AuthUI(firebase.auth());
  }

  ui.start(container, {
    ...uiConfig,
    callbacks: {
      signInSuccessWithAuthResult: successCallback
    },
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ]
  });
};

export const currentUser = {};

export function initAuth() {
  firebase.auth()
    .onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        updateState('currentUser', {
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          isAnonymous: user.isAnonymous,
          uid: user.uid,
          providerData: user.providerData,
        });

        updateState('isLoggedIn', true);
      } else {
        // User is signed out.
        updateState('currentUser', {});
        updateState('isLoggedIn', false);
      }
    });
}

export default {
  authUI,
};
