const firebase = {
  apiKey: "AIzaSyBDH40pS8yLN_KIUjz-vKgzVt0AytG9mFs",
  authDomain: "laoshi.firebaseapp.com",
  databaseURL: "https://laoshi.firebaseio.com",
  projectId: "laoshi",
  storageBucket: "laoshi.appspot.com",
  messagingSenderId: "315273815119",
  appId: "1:315273815119:web:dbc50ab0a0ad091ce0363a",
};

export default {
  firebase,
};
