import Login from "pages/Login";
import DefaultLayout from "layouts/Default";

export const routes = [
  {
    path: "/",
    component: DefaultLayout,
    freeAccess: true,
    title: "Laoshi - Home",
    routes: [
      {
        path: "/login",
        component: Login,
        freeAccess: true,
        title: "Laoshi - Login",
      },
    ],
  },
];
