import * as firebase from "firebase/app";
import config from 'config';

import { initAuth } from 'services/firebaseAuthService';

export function init() {
  firebase.initializeApp(config.firebase);
  initAuth();
}

export default firebase;
