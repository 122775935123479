import React from 'react';
import Page from 'pages/Page';
import styles from './styles.module.css';
import { Redirect } from "react-router-dom";

import authService from 'services/firebaseAuthService';
import classNames from 'helpers/classNames';
import 'firebaseui/dist/firebaseui.css';

import { mapState } from 'store/state';

export default class Login extends Page {
  constructor() {
    super();

    this.state = {
      ...mapState(this, ['isLoggedIn']),
    };
  }

  _login = () => {
    authService.authUI('#firebaseui-auth-container', this._onSuccess)
  }

  _onSuccess = (authResult, redirectUrl) => {
    if (authResult) {
      this.props.history.push(redirectUrl);
    }
  }

  _onFailure = (response) => {
    console.log('google auth failure', response);
  }

  render() {
    return (
      this.state.isLoggedIn ? (
        <Redirect to={{ pathname: "/" }} />
      ) : (
        <div {...classNames(styles.login, 'main column items-center')}>
          <h1>Welcome to Laoshi</h1>
          <button onClick={this._login}>Login</button>
          <div id="firebaseui-auth-container"></div>
        </div>
      )
    );
  }
}
