import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Page from 'pages/Page';
// import styles from './styles.module.css';
import { mapState } from 'store/state';

export default class DefaultLayout extends React.Component {
  constructor() {
    super();

    this.state = {
      ...mapState(this, ['isLoggedIn', 'currentUser']),
    };
  }
  render() {
    const { routes } = this.props;

    return (
      <div className="main">
        <div>Test { this.state.currentUser && this.state.currentUser.email }</div>
        <Switch>
          {routes.map((route, i) => (
            <Page exact key={i} {...route} />
          ))}
          <Route path="*">
            <Redirect to={{ pathname: "/" }} />
          </Route>
        </Switch>
      </div>
    );
  }
}
