const state =  {
  isLoggedIn: false,
  currentUser: {},
};

const listeners = Object.keys(state)
  .reduce((obj, key) => (
    Object.assign(obj, { [key]: [] })
  ), {});

function listenersGarbageCollection(prop) {
  listeners[prop] = listeners[prop].filter(listener => (
    listener.updater.isMounted(listener)
  ))
}

export function mapState(caller, selectedProps) {
  const selected = {};
  selectedProps.forEach(prop => {
    listenersGarbageCollection(prop);

    if (state.hasOwnProperty(prop)) {
      selected[prop] = state[prop];
      listeners[prop].push(caller)
    }
  });

  return Object.freeze(selected);
}

export function updateState(prop, value) {
  if (state.hasOwnProperty(prop)) {
    state[prop] = value;

    listenersGarbageCollection(prop);

    listeners[prop].forEach(listener => {
      if (listener.updater.isMounted(listener)) {
        listener.setState({ [prop]: value });
      } else {
        listener.updater.isMounted(listener)
      }
    });
  }
}

export default state
